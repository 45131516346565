export function Copy({
    fill = 'white', style = {}, className = '', onClick = undefined,
}) {
    return (
        <svg viewBox="0 0 18 18" fill="none" style={style} className={className} onClick={onClick}>
            <path
                d="M11.5842 3.14807H3.21635C2.43118 3.14807 1.79346 3.78579 1.79346 4.57096V16.5771C1.79346 17.3623 2.43118 18 3.21635 18H11.5842C12.3693 18 13.0071 17.3623 13.0071 16.5771V4.57096C13.0034 3.78579 12.3656 3.14807 11.5842 3.14807ZM12.0081 16.5734C12.0081 16.8093 11.8164 17.001 11.5805 17.001H3.21267C2.97675 17.001 2.78506 16.8093 2.78506 16.5734V4.57096C2.78506 4.33504 2.97675 4.14336 3.21267 4.14336H11.5805C11.8164 4.14336 12.0081 4.33504 12.0081 4.57096V16.5734Z"
                fill={fill}
            />
            <path
                d="M14.7839 0H6.41606C5.63089 0 4.99316 0.637723 4.99316 1.4229C4.99316 1.69937 5.21434 1.92054 5.49081 1.92054C5.76728 1.92054 5.98845 1.69937 5.98845 1.4229C5.98845 1.18698 6.18014 0.99529 6.41606 0.99529H14.7839C15.0198 0.99529 15.2115 1.18698 15.2115 1.4229V13.429C15.2115 13.665 15.0198 13.8566 14.7839 13.8566C14.5074 13.8566 14.2862 14.0778 14.2862 14.3543C14.2862 14.6308 14.5074 14.8519 14.7839 14.8519C15.569 14.8519 16.2068 14.2142 16.2068 13.429V1.4229C16.2068 0.637723 15.569 0 14.7839 0Z"
                fill={fill}
            />
        </svg>
    );
}
