import './toggle.scss';

interface ToggleProps {
    id: string;
    isToggled: boolean;
    isDisabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Toggle = ({
    id, onChange, isToggled, isDisabled,
}: ToggleProps) => {
    const onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange?.(e);
    };

    return (
        <label aria-disabled={isDisabled} className="toggle-container" htmlFor={id}>
            <input id={id} onChange={onToggle} type="checkbox" checked={isToggled} />
            <span className="toggle-slider toggle-round" />
        </label>
    );
};

Toggle.defaultProps = {
    onChange:   undefined,
    isDisabled: false,
};

export default Toggle;
