
import './LevelAvatar.scss';

export function LevelAvatar({
    levelNumber, style, className, fill, onClick,
}) {
    return (
        <div className="level-avatar-wrapper">
            <svg
                style={style}
                className={className}
                onClick={onClick}
                viewBox="0 0 428.85 404.69"
                width="428.85"
                height="404.69"
            >
                <path fill={fill || '#000'} d="M2,362.64c-.35-2.46.41-5.38-1.59-7.88-.52-.64-.31-1.92-.33-2.92-.06-2.33-.08-4.66-.08-7Q0,201.63,0,58.42A23.35,23.35,0,0,1,5.66,43.16C11,36.57,18.16,34.44,26.11,36a19.58,19.58,0,0,1,12,7.22C43.21,50,48.17,56.71,53.82,63c3.87,4.27,7,9.2,10.56,13.74,4.87,6.2,9.88,12.28,14.76,18.47,3.58,4.55,7,9.21,10.57,13.79s7,8.8,10.4,13.22c3.83,4.92,7.62,9.87,11.46,14.79,3.35,4.29,6.71,8.59,10.14,12.82.45.56,1.4.71,2.27,1.11,3.33-6.16,6.68-12,9.7-18,4.34-8.62,8.33-17.41,12.67-26,3.76-7.47,7.83-14.77,11.69-22.19,5.85-11.19,11.7-22.38,17.43-33.63,4.94-9.71,9.72-19.5,14.56-29.26,1.24-2.5,2.4-5,3.69-7.5C196,10,198.79,5.77,203.06,3.58c4.49-2.32,9.33-5.24,14.95-2.42,1.41.71,3.24.59,5,.87,7.1,3.27,10.36,9.83,13.7,16.38,4.33,8.48,8.86,16.87,13.23,25.33,5,9.67,9.87,19.4,14.87,29.06,2.81,5.42,5.93,10.67,8.64,16.13,5.07,10.22,9.84,20.59,14.94,30.79,3,6,6.52,11.82,9.69,17.77,2.23,4.19,4.28,8.48,6.48,12.88l2.37.38c8.59-11.08,17.05-22,25.57-32.95,5.69-7.29,11.49-14.48,17.18-21.76q14.13-18.12,28.17-36.29c3.84-5,7.68-9.91,11.4-14.94,5.6-7.6,13.41-10.53,22.23-8.74,7.23,1.46,12.67,6.4,15.31,13.58,2.47,6.71,2.11,13.74,1.77,20.74-.11,2.47,0,5-.08,7.44-.07,2.65-.36,5.3-.3,7.94.14,6.48.57,13,.64,19.45q.17,16,0,32c0,4.31-.65,8.63-.63,12.95.11,17.81.45,35.63.53,53.44q.15,32.49,0,65c0,5.16-.49,10.31-.5,15.46,0,4.31.48,8.63.5,12.95.1,16,.29,32,0,48a60.38,60.38,0,0,1-2.32,17.24A58.64,58.64,0,0,1,412,386.55a65.82,65.82,0,0,1-14.6,10.82,49.57,49.57,0,0,1-9,3.61c-11.56,3.71-23.45,3.68-35.36,3.7q-24,0-48-.07c-2.31,0-4.62-.42-6.94-.59a31.15,31.15,0,0,0-4-.14c-5.15.21-10.29.68-15.43.69q-108.23.06-216.44-.06c-6.44,0-13.13-.3-19.27-2-9.61-2.68-18.68-6.9-25.58-14.73-3.39-3.85-6.88-7.54-9.55-12A67.72,67.72,0,0,1,4,367.37C3.42,366,2.88,364.68,2,362.64Z" />
            </svg>

            {levelNumber != null ? <div className="level-avatar-number">{levelNumber}</div> : null}
        </div>
    );
}
