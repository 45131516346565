import { useNavigate } from 'react-router-dom';

import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';
import { ArrowCollapseUp } from 'components/svg';

import styles from './index.module.scss';

function CardSmall({
    isLoading,
    id,
    image,
    imagePlaceholder,
    imageHolderClassName,
    subtitle,
    title,
    onClickNavigateTo,
    onClick,
}) {
    const navigate = useNavigate();

    const handleClick = () => {
        if (isLoading) {
            return;
        }

        onClickNavigateTo ? navigate(onClickNavigateTo) : null;

        onClick?.();
    };

    return (
        <div id={id} className={styles.container} onClick={() => handleClick()}>
            <div>
                {image || imagePlaceholder ? (
                    <div className={`${ styles.imageHolder } ${ imageHolderClassName || '' }`}>
                        {image && <Img src={image} alt="card thumbnail" />}
                        {!image && imagePlaceholder ? imagePlaceholder : null}
                    </div>
                ) : null}

                <div className={styles.details}>
                    <h1>{title || 'N/A'}</h1>
                    {subtitle && <p>{subtitle}</p>}
                </div>
            </div>

            <div>
                {isLoading && <Loader wrapperStyle={{ marginTop: '5px' }} />}
                <ArrowCollapseUp style={{ transform: 'rotate(0.25turn)' }} />
            </div>
        </div>
    );
}

CardSmall.defaultProps = {
    id:                   undefined,
    image:                undefined,
    imagePlaceholder:     undefined,
    subtitle:             undefined,
    onClickNavigateTo:    undefined,
    onClick:              undefined,
    imageHolderClassName: undefined,
    isLoading:            false,
};

export default CardSmall;
