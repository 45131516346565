import isEmpty from 'lodash/isEmpty';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export declare interface ImgProps {
    src: string;
    alt?: string;
    className?: string;

    width?: number;
    height?: number;

    role?: string;
    style?: React.CSSProperties;
    visibleByDefault?: boolean;
    placeholderSrc?: string;
}

export default function Img({
    src,
    alt,
    className,
    style,
    width,
    height,
    role,
    visibleByDefault,
    placeholderSrc,
}: ImgProps) {
    return (
        !isEmpty(src) && (
            <LazyLoadImage
                className={className}
                src={src}
                alt={alt}
                width={width}
                height={height}
                role={role}
                style={style}
                threshold={100}

                // placeholder={<span />}
                placeholderSrc={placeholderSrc}
                effect={placeholderSrc ? undefined : 'blur'}
                visibleByDefault={visibleByDefault}
            />
        )
    );
}

Img.defaultProps = {
    alt:              undefined,
    className:        undefined,
    width:            undefined,
    height:           undefined,
    role:             undefined,
    style:            undefined,
    visibleByDefault: false,
    placeholderSrc:   undefined,
};
