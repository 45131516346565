import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import CardSmall from 'components/widgets/card-small';
import CardToggle from 'components/widgets/card-toggle/CardToggle';
import { introTooltipsKeys } from 'constants/introTooltips';
import useIntroTooltipAPI from 'hooks/intro-helper/useIntroTooltipAPI';
import useLocale from 'hooks/locale/useLocale';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { resumeOnboarding } from 'store/step-process/action';
import { updateTooltipKey } from 'store/user/action';

import './helpAndContact.scss';
import StoriesList from './stories-list/StoriesList';

function HelpAndContact() {
    const intl = useIntl();
    const lang = useLocale();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useGetCurrentUser();
    const tooltipAPI = useIntroTooltipAPI();

    const [isResumeOnboardingPending, setResumeOnboardingPending] = useState(false);
    const [isShopIntroTogglePending, setShopIntroTogglePending] = useState(false);
    const [isProfileIntroTogglePending, setProfileIntroTogglePending] = useState(false);

    const handleRedirectToHelpCenter = () => navigate(All_PATHS_NAMESPACES[lang]['help-center'].path);

    const handleRedirectToOnboarding = () => {
        setResumeOnboardingPending(true);
        dispatch(resumeOnboarding());

        setTimeout(() => {
            setResumeOnboardingPending(false);
            navigate(All_PATHS_NAMESPACES[lang].onboarding.path);
        }, 500);
    };

    const handleShopIntroToggle = (value: boolean) => {
        if (isShopIntroTogglePending) {
            return;
        }

        setShopIntroTogglePending(true);

        const isViewed = !value;

        tooltipAPI
                .updateValue({ key: introTooltipsKeys.tooltipShop, viewed: isViewed })
                .then(() => dispatch(updateTooltipKey(introTooltipsKeys.tooltipShop, isViewed)))
                .catch(() => dispatch(updateTooltipKey(introTooltipsKeys.tooltipShop, !isViewed)))
                .finally(() => setShopIntroTogglePending(false));
    };

    const handleProfileIntroToggle = (value: boolean) => {
        if (isProfileIntroTogglePending) {
            return;
        }

        setProfileIntroTogglePending(true);

        const isViewed = !value;

        tooltipAPI
                .updateValue({ key: introTooltipsKeys.tooltipProfile, viewed: isViewed })
                .then(() => dispatch(updateTooltipKey(introTooltipsKeys.tooltipProfile, isViewed)))
                .catch(() => dispatch(updateTooltipKey(introTooltipsKeys.tooltipProfile, !isViewed)))
                .finally(() => setProfileIntroTogglePending(false));
    };

    return (
        <PageTransitionWrapper className="help-and-contact-container">
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.help-and-contact' })} />

            <CardSmall
                onClick={handleRedirectToHelpCenter}
                subtitle={intl.formatMessage({ id: 'help-and-contact.menu.help-center.desc' })}
                title={intl.formatMessage({ id: 'help-and-contact.menu.help-center.title' })}
            />

            <StoriesList />

            {!currentUser.onboarded && (
                <CardSmall
                    onClick={handleRedirectToOnboarding}
                    subtitle={intl.formatMessage({ id: 'help-and-contact.menu.onboarding-toggler.desc' })}
                    title={intl.formatMessage({ id: 'help-and-contact.menu.onboarding-toggler.title' })}
                    isLoading={isResumeOnboardingPending}
                />
            )}

            <CardToggle
                toggleId="home-intro-toggler"
                subtitle={intl.formatMessage({ id: 'help-and-contact.menu.shop-intro-toggler.desc' })}
                title={intl.formatMessage({ id: 'help-and-contact.menu.shop-intro-toggler.title' })}
                defaultToggleValue={!currentUser?.tooltips?.HELP_TOOLTIP_SHOP}
                onToggleChange={handleShopIntroToggle}
                isLoading={isShopIntroTogglePending}
            />

            <CardToggle
                toggleId="profile-intro-toggler"
                subtitle={intl.formatMessage({ id: 'help-and-contact.menu.profile-intro-toggler.desc' })}
                title={intl.formatMessage({ id: 'help-and-contact.menu.profile-intro-toggler.title' })}
                defaultToggleValue={!currentUser?.tooltips?.HELP_TOOLTIP_PROFILE}
                onToggleChange={handleProfileIntroToggle}
                isLoading={isProfileIntroTogglePending}
            />
        </PageTransitionWrapper>
    );
}

export default HelpAndContact;
