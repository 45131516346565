import isEmpty from 'lodash/isEmpty';

import { application_type } from 'constants/api';

import { setUser } from '../user/action';
import * as t from './types';

export const setCurrentStep = (userCurrentStep) => (dispatch) => {
    dispatch({
        type:    t.SET_CURRENT_STEP,
        payload: userCurrentStep,
    });
};

export const setNextStep = () => (dispatch) => {
    dispatch({
        type: t.SET_NEXT_STEP,
    });
};

export const setNext =
    (shwoUserAdvancement = false, userObj = {}) => (dispatch) => {
        if (!isEmpty(userObj)) {
            const { totalPoints = null, firstName = null, lastName = null } = userObj;

            if (localStorage.getItem(`${ application_type }_user`)) {
                const user = JSON.parse(localStorage.getItem(`${ application_type }_user`));

                user.totalPoints = totalPoints;
                user.onboardingStep += 1;

                if (firstName) {
                    user.firstName = firstName;
                }

                if (lastName) {
                    user.lastName = lastName;
                }

                if (user.onboardingStep === 3 && user.onboarded === false) {
                    // user.onboarded = true;
                }

                localStorage.setItem(`${ application_type }_user`, JSON.stringify(user));
                dispatch(setUser(user));
            }
        }

        dispatch({
            type:    t.SET_NEXT,
            payload: shwoUserAdvancement,
        });
    };

export const setLater = () => (dispatch) => {
    dispatch({
        type: t.SET_LATER,
    });
};

export const clearProcess = () => (dispatch) => {
    dispatch({
        type: t.CLEAR_STEP_PROCESS,
    });
};

export const resumeOnboarding = () => (dispatch) => {
    dispatch({
        type: t.ONBOARDING_RESUME,
    });
};
