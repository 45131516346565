import React from 'react';

export function Checked() {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clipPath="url(#clip0_532_1012)">
                <path
                    d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z"
                    fill="#32BA7C"
                />
                <path
                    d="M10.7949 21.041L18.2508 28.4968C24.4258 26.8502 29 21.224 29 14.5C29 14.3628 29 14.2255 29 14.0883L23.1451 8.69085L10.7949 21.041Z"
                    fill="#0AA06E"
                />
                <path
                    d="M14.8658 17.7476C15.5062 18.388 15.5062 19.4858 14.8658 20.1262L13.5393 21.4527C12.8989 22.0931 11.8011 22.0931 11.1608 21.4527L5.35162 15.5978C4.71124 14.9574 4.71124 13.8596 5.35162 13.2192L6.67812 11.8927C7.3185 11.2524 8.41629 11.2524 9.05667 11.8927L14.8658 17.7476Z"
                    fill="white"
                />
                <path
                    d="M19.943 7.6388C20.5834 6.99842 21.6812 6.99842 22.3216 7.6388L23.6481 8.9653C24.2885 9.60568 24.2885 10.7035 23.6481 11.3438L13.585 21.3612C12.9446 22.0016 11.8468 22.0016 11.2064 21.3612L9.87994 20.0347C9.23956 19.3943 9.23956 18.2965 9.87994 17.6561L19.943 7.6388Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_532_1012">
                    <rect width="29" height="29" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
