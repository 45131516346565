import React from 'react';

export function ArrowCollapseUp({ className, style, fill }) {
    return (
        <svg
            className={className}
            style={style}
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
        >
            <path
                d="M6.95439 0.803725C6.80201 0.803725 6.64963 0.864669 6.52772 0.986574L0.981056 5.95419C0.706771 6.198 0.676294 6.65515 0.920104 6.92943C1.16391 7.20372 1.62106 7.2342 1.89534 6.99039L6.95439 2.41896L12.0134 6.99039C12.2877 7.2342 12.7449 7.23419 12.9887 6.92943C13.2325 6.62467 13.2325 6.198 12.9277 5.95419L7.41153 0.986574C7.3201 0.864669 7.13725 0.803725 6.95439 0.803725Z"
                fill={fill || 'white'}
            />
        </svg>
    );
}
