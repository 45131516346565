import React from 'react';

export function LockRounded({ style = {}, fill = '#000000' }) {
    return <svg style={style} viewBox="0 0 30 30" width="30px" height="30px">
        <path
            fill={fill}
            d="M 15 2 C 11.698375 2 9 4.6983746 9 8 L 9 11.304688 C 7.1627428 12.952391 6 15.337544 6 18 C 6 22.971 10.029 27 15 27 C 19.971 27 24 22.971 24 18 C 24 15.337544 22.837257 12.952391 21 11.304688 L 21 8 C 21 4.8243068 18.483967 2.2673277 15.355469 2.0722656 A 1.0001 1.0001 0 0 0 15 2 z M 15 4 C 17.220375 4 19 5.7796254 19 8 L 19 9.9453125 C 17.794081 9.3453537 16.438486 9 15 9 C 13.561514 9 12.205919 9.3453537 11 9.9453125 L 11 8 C 11 5.7796254 12.779625 4 15 4 z M 15 16 C 16.105 16 17 16.895 17 18 C 17 18.738 16.595 19.375656 16 19.722656 L 16 21 C 16 21.552 15.552 22 15 22 C 14.448 22 14 21.552 14 21 L 14 19.722656 C 13.405 19.376656 13 18.739 13 18 C 13 16.895 13.895 16 15 16 z"
        />
    </svg>
}

export function Lock({ style = {}, fill = '#000000' }) {
    return <svg style={style} viewBox="0 0 48 48" width="48px" height="48px">
        <path
            fill={fill}
            d="M 24 4 C 19.599415 4 16 7.599415 16 12 L 16 16 L 12.5 16 C 10.019 16 8 18.019 8 20.5 L 8 39.5 C 8 41.981 10.019 44 12.5 44 L 35.5 44 C 37.981 44 40 41.981 40 39.5 L 40 20.5 C 40 18.019 37.981 16 35.5 16 L 32 16 L 32 12 C 32 7.599415 28.400585 4 24 4 z M 24 7 C 26.779415 7 29 9.220585 29 12 L 29 16 L 19 16 L 19 12 C 19 9.220585 21.220585 7 24 7 z M 24 27 C 25.657 27 27 28.343 27 30 C 27 31.657 25.657 33 24 33 C 22.343 33 21 31.657 21 30 C 21 28.343 22.343 27 24 27 z"
        />
    </svg>
}


export function LockV2({ style = {}, fill = 'white', className = '' }) {
    return <svg style={style} className={className} width="25" height="31" viewBox="0 0 25 31" fill="none">
        <path 
            d="M23.3887 12.8841C22.4234 11.9096 21.1082 11.362 19.7364 11.3632H5.26305C3.89269 11.3656 2.57926 11.912 1.61128 12.8817C0.643045 13.8516 0.0996094 15.166 0.0996094 16.5364V25.8363C0.0996094 27.2059 0.643568 28.5191 1.61177 29.4876C2.58025 30.4558 3.89369 31 5.26305 31H19.7364C21.1058 31 22.4192 30.4558 23.3877 29.4876C24.3559 28.5191 24.8998 27.2059 24.8998 25.8363V16.5364C24.9037 15.166 24.3595 13.8512 23.3886 12.8841H23.3887ZM22.8364 25.8366C22.834 26.6581 22.5066 27.4451 21.9256 28.0258C21.3449 28.6067 20.5579 28.9342 19.7364 28.9366H5.2631C4.4416 28.9342 3.65459 28.6067 3.07392 28.0258C2.49299 27.4451 2.16554 26.6581 2.16311 25.8366V16.5367C2.16043 15.7137 2.48617 14.9238 3.06806 14.3419C3.64997 13.7597 4.4401 13.434 5.2631 13.4367H19.7364C20.5594 13.434 21.3496 13.7598 21.9315 14.3419C22.5134 14.9238 22.8391 15.7137 22.8364 16.5367V25.8366Z" 
            fill={fill}
        />
        <path 
            d="M6.29909 9.30002C6.57399 9.30002 6.83769 9.19079 7.0321 8.99642C7.22647 8.80204 7.3357 8.53833 7.3357 8.26341V7.23657C7.3357 5.39185 8.31975 3.68724 9.9173 2.76472C11.5148 1.84245 13.4834 1.84245 15.081 2.76472C16.6785 3.68718 17.6626 5.39185 17.6626 7.23657V8.26341C17.6626 8.63367 17.8601 8.97593 18.181 9.16108C18.5016 9.34621 18.8967 9.34621 19.2176 9.16108C19.5382 8.97595 19.7358 8.63366 19.7358 8.26341V7.23657C19.7358 4.65105 18.3565 2.26214 16.1176 0.96957C13.8785 -0.32319 11.1198 -0.32319 8.8809 0.96957C6.64208 2.26233 5.2627 4.65124 5.2627 7.23657V8.26341C5.2627 8.53831 5.37192 8.80201 5.5663 8.99642C5.76067 9.19079 6.02438 9.30002 6.29931 9.30002H6.29909Z" 
            fill={fill}
        />
        <path 
            d="M12.4991 16.7883C11.5168 16.7839 10.5909 17.2471 10.0051 18.0355C9.41929 18.824 9.24343 19.844 9.53095 20.7833C9.8185 21.7226 10.5357 22.4691 11.4626 22.7944V24.5382C11.4626 24.9087 11.6601 25.2507 11.981 25.4359C12.3016 25.6212 12.6967 25.6212 13.0173 25.4359C13.3382 25.2507 13.5357 24.9087 13.5357 24.5382V22.7944C14.4626 22.4692 15.1797 21.7226 15.4673 20.7833C15.7549 19.844 15.579 18.824 14.9932 18.0355C14.4074 17.2471 13.4815 16.7839 12.4992 16.7883H12.4991ZM12.4991 20.9249C12.2242 20.9249 11.9605 20.8157 11.7661 20.6213C11.5717 20.4269 11.4625 20.1632 11.4625 19.8883C11.4625 19.6134 11.5717 19.3497 11.7661 19.1553C11.9605 18.9609 12.2242 18.8517 12.4991 18.8517C12.774 18.8517 13.0377 18.9609 13.2321 19.1553C13.4265 19.3497 13.5357 19.6134 13.5357 19.8883C13.5357 20.1632 13.4265 20.4269 13.2321 20.6213C13.0378 20.8157 12.774 20.9249 12.4991 20.9249Z" 
            fill={fill}
        />
    </svg>
}