import { ProgressDayPreview1 } from './ProgressDayPreview1';
import { ProgressDayPreview2 } from './ProgressDayPreview2';
import { ProgressDayPreview3 } from './ProgressDayPreview3';
import { ProgressDayPreview4 } from './ProgressDayPreview4';
import { ProgressDayPreview5 } from './ProgressDayPreview5';
import { ProgressDayPreview6 } from './ProgressDayPreview6';
import { ProgressDayPreview7 } from './ProgressDayPreview7';

export const ProgressDayPreview = {
    day1: ProgressDayPreview1,
    day2: ProgressDayPreview2,
    day3: ProgressDayPreview3,
    day4: ProgressDayPreview4,
    day5: ProgressDayPreview5,
    day6: ProgressDayPreview6,
    day7: ProgressDayPreview7,
};
