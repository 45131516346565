import './storyCard.scss';

interface StoryCard {
    image: string;
    onClick: () => void;
}

const StoryCard = ({ image, onClick }) => (
    <div className="story-card" onClick={onClick}>
        {image && <img src={image} alt="thumbnail" />}
    </div>
);

export default StoryCard;
