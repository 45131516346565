export const Wallet = ({ className, style, onClick }) => (
    <svg className={className} style={style} onClick={onClick} viewBox="0 0 27 24" fill="none">
        <path
            d="M21.4272 12.0698C20.8706 12.0698 20.3371 12.2908 19.9438 12.6843C19.5505 13.0778 19.3294 13.6114 19.3296 14.1677C19.3296 14.7243 19.5507 15.2578 19.9442 15.6511C20.3377 16.0444 20.8712 16.2655 21.4276 16.2653C21.9841 16.2653 22.5177 16.0442 22.911 15.6507C23.3043 15.2572 23.5253 14.7237 23.5251 14.1671C23.5245 13.611 23.3033 13.0776 22.9099 12.6845C22.5166 12.2912 21.9833 12.0702 21.4272 12.0698ZM21.4272 15.0405C21.1957 15.0403 20.9736 14.9484 20.8099 14.7846C20.6463 14.6209 20.5544 14.3988 20.5544 14.1673C20.5544 13.936 20.6465 13.7139 20.8101 13.5503C20.9738 13.3866 21.1959 13.2946 21.4272 13.2946C21.6587 13.2946 21.8808 13.3864 22.0444 13.5501C22.2082 13.7137 22.3001 13.9358 22.3003 14.1671C22.3001 14.3986 22.208 14.6207 22.0442 14.7843C21.8806 14.9482 21.6587 15.0403 21.4272 15.0405Z"
            fill="white"
            stroke="white"
            strokeWidth="0.3"
        />
        <path
            d="M26 9.31693C25.9988 9.22445 25.9758 9.1334 25.9336 9.05097C25.6768 7.56693 24.6675 6.32285 23.2682 5.76572L19.1299 1.20027C18.9443 0.997464 18.6468 0.942097 18.4007 1.06452L13.6981 3.43153L11.5198 1.7488C11.302 1.58003 10.9985 1.57757 10.7781 1.74285L5.81705 5.45872H5.32224C4.17638 5.45995 3.07765 5.91579 2.26729 6.72621C1.45708 7.53642 1.00126 8.6351 1 9.78117V18.5542C1.00123 19.7002 1.45708 20.7987 2.26729 21.6091C3.07771 22.4195 4.17638 22.8753 5.32224 22.8766H21.6776C22.8235 22.8754 23.9222 22.4195 24.7326 21.6091C25.5428 20.7987 25.9986 19.7002 25.9999 18.5542V9.78117C25.9999 9.65588 25.9917 9.53284 25.981 9.41022H25.9812C25.99 9.37966 25.9962 9.34849 25.9999 9.31692L26 9.31693ZM18.5352 2.36815L21.3361 5.45838L16.3224 5.45859L14.7731 4.26185L18.5352 2.36815ZM11.1391 3.00282L14.3188 5.45859H7.86082L11.1391 3.00282ZM5.32216 6.68341H21.6775C22.3083 6.68505 22.9235 6.87965 23.4407 7.24075C23.9578 7.60206 24.3523 8.11264 24.5711 8.70447H2.42868C2.64727 8.11269 3.04182 7.60207 3.55895 7.24075C4.07611 6.87964 4.69127 6.68504 5.32205 6.68341H5.32216ZM21.6775 21.6519H5.32216C4.50108 21.6509 3.71365 21.3244 3.13295 20.7437C2.55225 20.1629 2.22557 19.3755 2.22473 18.5542V9.92929H24.7751V18.5542C24.7743 19.3755 24.4476 20.163 23.8669 20.7437C23.2861 21.3244 22.4987 21.6508 21.6777 21.6519H21.6775Z"
            fill="white"
            stroke="white"
            strokeWidth="0.3"
        />
    </svg>
);

Wallet.defaultProps = {
    className: undefined,
    style:     undefined,
    onClick:   undefined,
};
