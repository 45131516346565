export const ProgressDayPreview4 = ({ className }: { className?: string }) => (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" className={className}>
        <g clipPath="url(#clip0_2615_1909)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.14286 2.34921C10.4286 1.7619 12 1.1746 13.4286 0.880952C14.8571 0.440476 16.4286 0.146825 17.8571 0H18H18.1429C19.5714 0.146825 21.2857 0.440476 22.5714 0.880952C24 1.32143 25.5714 1.7619 26.8571 2.34921H27L27.1429 2.49603C28.2857 3.37698 29.5714 4.55159 30.5714 5.57937C31.5714 6.60714 32.7143 7.92857 33.5714 9.10317L33.7143 9.25V9.39683C34.2857 10.7183 34.8571 12.3333 35.1429 13.8016C35.5714 15.2698 35.8571 16.8849 36 18.3532V18.5V18.6468C35.8571 20.1151 35.5714 21.877 35.1429 23.1984C34.7143 24.6667 34.2857 26.2817 33.7143 27.6032V27.75L33.5714 27.8968C32.7143 29.0714 31.5714 30.3929 30.5714 31.4206C29.5714 32.4484 28.2857 33.623 27.1429 34.504L27 34.6508H26.8571C25.5714 35.2381 24 35.8254 22.5714 36.119C21.1429 36.5595 19.5714 36.8532 18.1429 37H18H17.8571C16.4286 36.8532 14.7143 36.5595 13.4286 36.119C12 35.6786 10.4286 35.2381 9.14286 34.6508H9L8.85714 34.504C7.71428 33.623 6.42857 32.4484 5.42857 31.4206C4.42857 30.3929 3.28571 29.0714 2.42857 27.8968L2.28571 27.75V27.6032C1.71429 26.2817 1.14286 24.6667 0.857143 23.1984C0.428571 21.7302 0.142857 20.1151 0 18.6468V18.5V18.3532C0.142857 16.8849 0.428571 15.123 0.857143 13.8016C1.28571 12.3333 1.71429 10.7183 2.28571 9.39683V9.25L2.42857 9.10317C3.28571 7.92857 4.42857 6.60714 5.42857 5.57937C6.42857 4.55159 7.71428 3.37698 8.85714 2.49603L9.14286 2.34921Z" fill="#232C45"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.715 1.9087C15.0007 1.46823 16.5721 1.17458 18.0007 1.02775V-0.146851H17.8578C16.4293 -2.5183e-05 14.715 0.293626 13.4293 0.734102C12.0007 1.17458 10.4293 1.61505 9.14356 2.20236H9.0007L8.85784 2.49601C7.71498 3.37696 6.42927 4.55156 5.42927 5.57934C4.42927 6.60712 3.28641 7.92855 2.42927 9.10315V9.24998V9.3968C1.85784 10.7182 1.28641 12.3333 1.0007 13.8016C0.572126 15.2698 0.286412 16.8849 0.143555 18.3531V18.5H1.28641C1.42927 17.0317 1.71498 15.5635 2.14355 14.0952C2.57213 12.7738 2.85784 11.1587 3.57213 9.83728C4.28641 8.66267 5.42927 7.48807 6.42927 6.46029C7.42927 5.43251 8.57213 4.40474 9.71498 3.52378C10.8578 2.78966 12.2864 2.34918 13.715 1.9087Z" fill="url(#paint0_linear_2615_1909)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.1436 1.1746C19.5721 1.32143 21.0007 1.61508 22.4293 2.05556C23.715 2.49603 25.2864 2.93651 26.5721 3.52381C27.715 4.25794 28.8578 5.43254 29.8578 6.46032C30.8578 7.4881 31.8578 8.6627 32.715 9.8373C33.2864 11.1587 33.715 12.627 34.1436 14.0952C34.5721 15.4167 34.8578 17.0317 35.0007 18.5H36.1436V18.3532C36.0007 16.8849 35.715 15.123 35.2864 13.8016C34.8578 12.3333 34.4293 10.7183 33.8578 9.39683V9.25L33.715 9.10317C32.8578 7.92857 31.715 6.60714 30.715 5.57937C29.715 4.55159 28.4293 3.37698 27.2864 2.49603L27.1436 2.34921H27.0007C25.715 1.7619 24.1436 1.1746 22.715 0.880952C21.2864 0.440476 19.715 0.146825 18.2864 0H18.1436V1.1746Z" fill="url(#paint1_linear_2615_1909)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.2857 1.90872C21 1.46825 19.4286 1.1746 18 1.02777V2.20237C18.8571 2.20237 20.2857 2.49602 22 3.08333C23.7143 3.5238 25.1429 4.1111 25.8571 4.40476C26.5714 4.84523 27.7143 5.87301 29 7.19444C30.2857 8.51587 31.2857 9.69047 31.7143 10.4246C32.1429 11.1587 32.5714 12.627 33 14.3889C33.4286 16.1508 33.7143 17.619 33.8571 18.5H35C34.8571 17.0317 34.5714 15.5635 34.1429 14.0952C33.7143 12.7738 33.4286 11.1587 32.7143 9.83729C32 8.66269 30.8571 7.48809 29.8571 6.46031C28.8571 5.43253 27.7143 4.40476 26.5714 3.5238C25.1429 2.78968 23.7143 2.3492 22.2857 1.90872Z" fill="url(#paint2_linear_2615_1909)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.0007 2.20237V1.02777C16.5721 1.1746 15.1436 1.46825 13.715 1.90872C12.4293 2.3492 10.8578 2.78968 9.57213 3.37698C8.42927 4.1111 7.28641 5.28571 6.28641 6.31349C5.28641 7.34126 4.28641 8.51587 3.42927 9.69047C2.85784 11.0119 2.42927 12.4802 2.0007 13.9484C1.57213 15.2698 1.28641 16.8849 1.14355 18.3532H2.28641C2.28641 17.4722 2.57213 16.004 3.14355 14.2421C3.42927 12.627 3.85784 11.1587 4.28641 10.4246C4.71498 9.69047 5.71498 8.51587 7.0007 7.19444C8.28641 5.87301 9.42927 4.84523 10.1436 4.40475C10.8578 3.96428 12.2864 3.5238 14.0007 3.08333C15.715 2.64285 17.1436 2.3492 18.0007 2.20237Z" fill="url(#paint3_linear_2615_1909)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.85742 22.9048C1.42885 21.5833 1.14314 19.9683 1.00028 18.5H-0.142578V18.6468C0.000279009 20.1151 0.285993 21.877 0.714565 23.1984C1.14314 24.6667 1.57171 26.2817 2.14314 27.6032V27.75L2.42885 27.8968C3.28599 29.0714 4.42885 30.3929 5.42885 31.4206C6.42885 32.4484 7.71456 33.623 8.85742 34.504L9.00028 34.6508H9.14314C10.4289 35.2381 12.0003 35.8254 13.4288 36.119C14.8574 36.5595 16.4289 36.8532 17.8574 37V35.8254C16.4289 35.6786 15.0003 35.3849 13.7146 34.9444C12.4289 34.504 10.8574 34.0635 9.57171 33.4762C8.42885 32.7421 7.28599 31.5675 6.28599 30.5397C5.28599 29.5119 4.28599 28.3373 3.42885 27.1627C2.71456 25.8413 2.28599 24.373 1.85742 22.9048Z" fill="url(#paint4_linear_2615_1909)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.14355 18.6468C1.28641 20.115 1.57213 21.5833 2.0007 23.0516C2.42927 24.373 2.85784 25.9881 3.42927 27.3095C4.14355 28.4841 5.28641 29.6587 6.28641 30.6865C7.28641 31.7142 8.42927 32.742 9.57213 33.623C10.8578 34.2103 12.2864 34.6508 13.715 35.0912C15.0007 35.3849 16.5721 35.8254 17.8578 35.9722V34.7976C17.0007 34.6508 15.5721 34.5039 14.0007 34.0635C12.2864 33.623 10.8578 33.0357 10.1436 32.742C9.42927 32.3015 8.28641 31.2738 7.0007 29.9523C5.71498 28.6309 4.71498 27.3095 4.28641 26.7222C3.85784 25.9881 3.42927 24.5198 3.0007 22.7579C2.57213 20.996 2.28641 19.5277 2.14355 18.6468H1.14355Z" fill="url(#paint5_linear_2615_1909)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.0003 33.9166C20.286 34.3571 18.8574 34.6508 18.0003 34.7976H17.8574V35.9722C19.286 35.8254 20.7146 35.5317 22.1431 35.0912C23.4289 34.6508 25.0003 34.2103 26.286 33.623C27.4289 32.8889 28.5717 31.7142 29.5717 30.6865C30.5717 29.6587 31.5717 28.4841 32.4289 27.3095C33.0003 25.9881 33.4289 24.5198 33.8574 23.0516C34.286 21.7301 34.5717 20.115 34.7146 18.6468H33.5717C33.5717 19.5277 33.286 20.996 32.7146 22.7579C32.286 24.5198 31.7146 25.9881 31.4289 26.7222C31.0003 27.4563 30.0003 28.6309 28.7146 29.9523C27.4289 31.2738 26.286 32.3015 25.5717 32.742C25.1431 33.0357 23.7146 33.4762 22.0003 33.9166Z" fill="url(#paint6_linear_2615_1909)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.2857 35.0912C21 35.5317 19.4286 35.8254 18 35.9722V37.1468H18.1429H18.2857C19.7143 37 21.4286 36.7063 22.7143 36.2658C24.1429 35.8254 25.7143 35.3849 27 34.7976H27.1429L27.2857 34.6508C28.4286 33.7698 29.7143 32.5952 30.7143 31.5674C31.7143 30.5396 32.8571 29.2182 33.7143 28.0436L33.8571 27.8968V27.75C34.4286 26.4285 35 24.8135 35.2857 23.3452C35.7143 21.8769 36 20.2619 36.1429 18.7936V18.6468H35C34.8571 20.115 34.5714 21.5833 34.1429 23.0516C33.7143 24.373 33.2857 25.9881 32.7143 27.3095C32 28.4841 30.8571 29.6587 29.8571 30.6865C28.8571 31.7142 27.7143 32.742 26.5714 33.623C25.1429 34.2103 23.7143 34.6508 22.2857 35.0912Z" fill="url(#paint7_linear_2615_1909)"/>
            <path opacity="0.75" fillRule="evenodd" clipRule="evenodd" d="M4.85742 7.92858C6.14314 6.3135 7.71456 4.69842 9.28599 3.23016C12.286 2.05556 15.1431 1.32143 18.0003 0.734131C20.5717 1.32143 23.0003 2.20238 25.4289 3.08334C23.0003 2.49604 20.5717 1.76191 18.0003 1.46826C15.1431 1.76191 12.286 2.49604 9.71456 3.81746C7.85742 4.99207 6.28599 6.46032 4.85742 7.92858Z" fill="#FFFECA"/>
            <path opacity="0.75" fillRule="evenodd" clipRule="evenodd" d="M31.1427 29.2182C29.857 30.8333 28.2856 32.4484 26.7141 33.9166C23.7141 35.0912 20.857 35.8253 17.9999 36.4126C15.4284 35.8253 12.9999 34.9444 10.5713 34.0634C12.9999 34.6507 15.4284 35.3849 17.9999 35.6785C20.857 35.3849 23.7141 34.6507 26.2856 33.3293C28.1427 32.0079 29.7141 30.5396 31.1427 29.2182Z" fill="#FFFECA"/>
            <mask id="path-12-outside-1_2615_1909" maskUnits="userSpaceOnUse" x="9.53906" y="9.32056" width="15" height="18" fill="black">
                <rect fill="white" x="9.53906" y="9.32056" width="15" height="18"/>
                <path d="M10.8272 23.5472V20.6032L17.5685 10.9606H21.6432V20.3899H23.3925V23.5472H21.6432V26.3206H17.9952V23.5472H10.8272ZM18.2512 14.9712L14.6672 20.3899H18.2512V14.9712Z"/>
            </mask>
            <path d="M10.8272 23.5472V20.6032L17.5685 10.9606H21.6432V20.3899H23.3925V23.5472H21.6432V26.3206H17.9952V23.5472H10.8272ZM18.2512 14.9712L14.6672 20.3899H18.2512V14.9712Z" fill="white"/>
            <path d="M10.8272 23.5472H9.82718V24.5472H10.8272V23.5472ZM10.8272 20.6032L10.0076 20.0302L9.82718 20.2883V20.6032H10.8272ZM17.5685 10.9606V9.96056H17.0475L16.7489 10.3876L17.5685 10.9606ZM21.6432 10.9606H22.6432V9.96056H21.6432V10.9606ZM21.6432 20.3899H20.6432V21.3899H21.6432V20.3899ZM23.3925 20.3899H24.3925V19.3899H23.3925V20.3899ZM23.3925 23.5472V24.5472H24.3925V23.5472H23.3925ZM21.6432 23.5472V22.5472H20.6432V23.5472H21.6432ZM21.6432 26.3206V27.3206H22.6432V26.3206H21.6432ZM17.9952 26.3206H16.9952V27.3206H17.9952V26.3206ZM17.9952 23.5472H18.9952V22.5472H17.9952V23.5472ZM18.2512 14.9712H19.2512V11.6466L17.4171 14.4196L18.2512 14.9712ZM14.6672 20.3899L13.8331 19.8382L12.8068 21.3899H14.6672V20.3899ZM18.2512 20.3899V21.3899H19.2512V20.3899H18.2512ZM11.8272 23.5472V20.6032H9.82718V23.5472H11.8272ZM11.6468 21.1762L18.3881 11.5335L16.7489 10.3876L10.0076 20.0302L11.6468 21.1762ZM17.5685 11.9606H21.6432V9.96056H17.5685V11.9606ZM20.6432 10.9606V20.3899H22.6432V10.9606H20.6432ZM21.6432 21.3899H23.3925V19.3899H21.6432V21.3899ZM22.3925 20.3899V23.5472H24.3925V20.3899H22.3925ZM23.3925 22.5472H21.6432V24.5472H23.3925V22.5472ZM20.6432 23.5472V26.3206H22.6432V23.5472H20.6432ZM21.6432 25.3206H17.9952V27.3206H21.6432V25.3206ZM18.9952 26.3206V23.5472H16.9952V26.3206H18.9952ZM17.9952 22.5472H10.8272V24.5472H17.9952V22.5472ZM17.4171 14.4196L13.8331 19.8382L15.5013 20.9416L19.0853 15.5229L17.4171 14.4196ZM14.6672 21.3899H18.2512V19.3899H14.6672V21.3899ZM19.2512 20.3899V14.9712H17.2512V20.3899H19.2512Z" fill="#1A1F2C" mask="url(#path-12-outside-1_2615_1909)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_2615_1909" x1="9.00527" y1="18.4564" x2="9.00527" y2="0.000709175" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D19300"/>
                <stop offset="1" stopColor="#FFE53B"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2615_1909" x1="27.0178" y1="18.5721" x2="27.0178" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D19300"/>
                <stop offset="1" stopColor="#FFDD00"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2615_1909" x1="26.4713" y1="18.5686" x2="26.4713" y2="1.11866" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFCC00"/>
                <stop offset="1" stopColor="#BD6E00"/>
            </linearGradient>
            <linearGradient id="paint3_linear_2615_1909" x1="9.54755" y1="18.4601" x2="9.54755" y2="1.11954" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDD00"/>
                <stop offset="1" stopColor="#CF7900"/>
            </linearGradient>
            <linearGradient id="paint4_linear_2615_1909" x1="8.94656" y1="37.0253" x2="8.94656" y2="18.4527" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BD6E00"/>
                <stop offset="1" stopColor="#FFCC00"/>
            </linearGradient>
            <linearGradient id="paint5_linear_2615_1909" x1="9.49284" y1="35.9067" x2="9.49284" y2="18.4564" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFE53B"/>
                <stop offset="1" stopColor="#D19300"/>
            </linearGradient>
            <linearGradient id="paint6_linear_2615_1909" x1="26.4171" y1="35.9151" x2="26.4171" y2="18.5652" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDD00"/>
                <stop offset="1" stopColor="#D19300"/>
            </linearGradient>
            <linearGradient id="paint7_linear_2615_1909" x1="26.9591" y1="37.0336" x2="26.9591" y2="18.5685" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7900"/>
                <stop offset="1" stopColor="#FFDD00"/>
            </linearGradient>
            <clipPath id="clip0_2615_1909">
                <rect width="36" height="37" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

ProgressDayPreview4.defaultProps = {
    className: undefined,
};
