import { json, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import useLocale from 'hooks/locale/useLocale';
import StoryCard from 'pages/story/story-card/StoryCard';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import Loader from 'components/loaders/loader';
import { generateImageUrl } from 'services/url';
import replacePathParams from 'services/utils/replacePathParams';
import './storiesList.scss';
import { StoryItem } from 'shared/interfaces/story';
import TAOOHelmet from 'components/helmet/Helmet';
import { Fragment } from 'react';
import useGetCachedStories from './useGetCachedStories';

const StoriesList = () => {
    const navigate = useNavigate();
    const lang = useLocale();
    const { stories, hasActiveStories, isLoading } = useGetCachedStories();

    const goToStory = (story: StoryItem) => {
        const storageKey = 'cached-story';

        localStorage.setItem(storageKey, JSON.stringify(story));

        const route = replacePathParams(
            All_PATHS_NAMESPACES[lang].story.path,
            {},
            ':',
            {
                key: storageKey,
            },
        );

        navigate(route);
    };

    return (hasActiveStories || isLoading) && (
        <div className="cached-stories">
            {isLoading && <Loader />}
            {stories.map((story, idx) => (
                <Fragment key={idx}>
                    {/* preload each story slides */}
                    <TAOOHelmet>
                        {
                            Object
                                    .entries(story)
                                    .filter(([key, value]) => key.includes('photo') && !isEmpty(value))
                                    .map(([, image], idx) => (
                                        <link
                                            key={`meta-${ idx }`}
                                            rel="preload"
                                            as="image"
                                            href={generateImageUrl(image)}
                                        />
                                    ))
                        }
                    </TAOOHelmet>

                    <StoryCard
                        key={`${ story.id }-${ idx }`}
                        image={generateImageUrl(story.thumbnail)}
                        onClick={() => goToStory(story)}
                    />
                </Fragment>
            ))}
        </div>
    );
};

export default StoriesList;
