import { domain } from 'constants/api';

export const isUrl = (url) => url && (url.includes('https://') || url.includes('http://'));

export const generateImageUrl = (image) => {
    if (!image) {
        return false;
    }

    return !isUrl(image) ? `${ domain }/uploads/${ image }` : image;
};
