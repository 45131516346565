import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Img from 'components/img/Img';
import Loader from 'components/loaders/loader';

import Toggle from '../toggle/Toggle';
import './cardToggle.scss';

interface CardToggleProps {
    id?: string;
    toggleId: string;
    image?: string;
    imagePlaceholder?: ReactNode;
    subtitle?: string;
    title: string;
    onClickNavigateTo?: string;
    onToggleChange?: (value: boolean) => void;
    defaultToggleValue?: boolean;
    isLoading?: boolean;
}

function CardToggle({
    id,
    image,
    imagePlaceholder,
    subtitle,
    title,
    onClickNavigateTo,
    toggleId,
    onToggleChange,
    defaultToggleValue,
    isLoading,
}: CardToggleProps) {
    const navigate = useNavigate();
    const [isToggled, setIsToggled] = useState(defaultToggleValue);

    const handleClick = () => {
        if (isLoading) {
            return;
        }

        onChange();

        onClickNavigateTo ? navigate(onClickNavigateTo) : null;
    };

    const onChange = () => {
        if (isLoading) {
            return;
        }

        setIsToggled(!isToggled);
        onToggleChange && onToggleChange?.(!isToggled);
    };

    return (
        <div id={id} className="card-toggle-container" onClick={() => handleClick()}>
            <div>
                {image || imagePlaceholder ? (
                    <div className="card-toggle-image-holder">
                        {image && <Img src={image} alt="card thumbnail" />}
                        {!image && imagePlaceholder ? imagePlaceholder : null}
                    </div>
                ) : null}

                <div className="card-toggle-details">
                    <h1>{title || 'N/A'}</h1>
                    {subtitle && <p>{subtitle}</p>}
                </div>
            </div>

            <div>
                {isLoading && <Loader wrapperStyle={{ marginTop: '5px' }} />}
                <Toggle isDisabled={isLoading} id={toggleId} onChange={onChange} isToggled={isToggled} />
            </div>
        </div>
    );
}

CardToggle.defaultProps = {
    id:                 undefined,
    image:              undefined,
    imagePlaceholder:   undefined,
    subtitle:           undefined,
    onClickNavigateTo:  undefined,
    onToggleChange:     undefined,
    defaultToggleValue: false,
    isLoading:          false,
};

export default CardToggle;
