import React from 'react';

export function Done({ stye, fill }) {
    return (
        <svg style={stye} viewBox="0 0 24 24" width="24px" height="24px">
            <path
                fill={fill || '#000000'}
                d="M9 19.4L3.3 13.7 4.7 12.3 9 16.6 20.3 5.3 21.7 6.7z"
            />
        </svg>
    );
}
