export const ProgressTube = () => (
    <svg width="366" height="18" viewBox="0 0 366 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M359.66 0C360.517 0.7875 361.888 2.475 363.258 4.3875C364.629 6.3 365.657 7.9875 366 9C365.657 10.0125 364.629 11.7 363.258 13.6125C361.888 15.525 360.688 17.1 359.66 18H6.16854C5.3118 17.2125 3.94101 15.525 2.57022 13.6125C1.37079 11.7 0.342697 10.0125 0 9C0.342697 7.9875 1.37079 6.3 2.74157 4.3875C4.11236 2.475 5.3118 0.7875 6.16854 0H359.66Z" fill="#CC6633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.7666 4.16248V13.8375L10.6233 14.4H355.205L356.233 13.8375V4.16248L355.205 3.59998H10.6233L9.7666 4.16248Z" fill="#1A1F2C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.79775 9C5.3118 6.6375 5.99719 4.1625 7.02528 1.8L6.16854 0C5.3118 0.7875 4.11236 2.475 2.74157 4.3875C1.37079 6.3 0.342697 7.9875 0 9H4.79775Z" fill="url(#paint0_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.19673 1.79999C6.16864 4.16249 5.3119 6.63749 4.79785 8.99999H9.5956V4.16249L7.19673 1.79999Z" fill="url(#paint1_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M361.031 9C360.517 6.6375 359.832 4.1625 358.804 1.8L359.66 0C360.517 0.7875 361.888 2.475 363.259 4.3875C364.63 6.3 365.658 7.9875 366 9H361.031Z" fill="url(#paint2_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M358.804 1.79999C359.832 4.16249 360.688 6.63749 361.031 8.99999H356.233V4.16249L358.804 1.79999Z" fill="url(#paint3_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.19703 1.8H358.804L359.66 0H6.16895L7.19703 1.8Z" fill="url(#paint4_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M358.803 1.79999H7.19629L9.76651 4.16249L10.6233 3.59999H355.205L356.233 4.16249L358.803 1.79999Z" fill="url(#paint5_radial_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.79775 9C5.3118 11.3625 5.99719 13.8375 7.02528 16.2L6.16854 18C5.3118 17.2125 3.94101 15.525 2.57022 13.6125C1.37079 11.7 0.342697 10.0125 0 9H4.79775Z" fill="url(#paint6_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.19673 16.2C6.16864 13.8375 5.3119 11.3625 4.79785 9H9.5956V13.8375L7.19673 16.2Z" fill="url(#paint7_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M361.031 9C360.517 11.3625 359.832 13.8375 358.804 16.2L359.66 18C360.517 17.2125 361.888 15.525 363.259 13.6125C364.63 11.7 365.658 10.0125 366 9H361.031Z" fill="url(#paint8_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M358.804 16.2C359.832 13.8375 360.688 11.3625 361.031 9H356.233V13.8375L358.804 16.2Z" fill="url(#paint9_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.19703 16.2H358.804L359.66 18H6.16895L7.19703 16.2Z" fill="url(#paint10_linear_2615_1798)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M358.803 16.2H7.19629L9.76651 13.8375L10.6233 14.4H355.205L356.233 13.8375L358.803 16.2Z" fill="url(#paint11_radial_2615_1798)"/>
        <path opacity="0.85" fillRule="evenodd" clipRule="evenodd" d="M5.6543 5.96248L6.68239 1.46248L6.33969 0.224976L7.19643 1.34998L182.657 1.79998L7.71048 1.91248L8.56722 3.03748L7.19643 2.02498L5.6543 5.96248Z" fill="#FFF9E1"/>
        <path opacity="0.85" fillRule="evenodd" clipRule="evenodd" d="M5.65457 6.1875L4.45513 8.55L0.342773 9L4.45513 9.45L5.48322 11.475L5.14053 9.225L6.85401 9L5.14053 8.775L5.65457 6.1875Z" fill="#FFF9E1"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M321.449 14.4H43.6934C80.1906 14.85 129.025 15.1875 182.486 15.1875C236.118 15.1875 284.952 14.85 321.449 14.4Z" fill="#F3DB35"/>
        <path opacity="0.85" fillRule="evenodd" clipRule="evenodd" d="M272.615 16.2L357.775 15.8625L356.576 14.175L358.632 15.525L359.66 13.8375L359.146 16.3125V16.875L358.289 16.425L272.615 16.2Z" fill="#FFF9E1"/>
        <defs>
            <linearGradient id="paint0_linear_2615_1798" x1="0" y1="4.49854" x2="7.15156" y2="4.49854" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFE89C"/>
                <stop offset="1" stopColor="#E8B723"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2615_1798" x1="7.30536" y1="8.99695" x2="7.30536" y2="1.78244" gradientUnits="userSpaceOnUse">
                <stop stopColor="#DB8E00"/>
                <stop offset="1" stopColor="#B87200"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2615_1798" x1="358.78" y1="4.49854" x2="365.932" y2="4.49854" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E8B723"/>
                <stop offset="1" stopColor="#FFE89C"/>
            </linearGradient>
            <linearGradient id="paint3_linear_2615_1798" x1="358.627" y1="8.99695" x2="358.627" y2="1.78244" gradientUnits="userSpaceOnUse">
                <stop stopColor="#DB8E00"/>
                <stop offset="1" stopColor="#B87200"/>
            </linearGradient>
            <linearGradient id="paint4_linear_2615_1798" x1="182.966" y1="1.78245" x2="182.966" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFC926"/>
                <stop offset="1" stopColor="#FFE89C"/>
            </linearGradient>
            <radialGradient id="paint5_radial_2615_1798" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.965 2.97077) scale(176.958 116.183)">
                <stop stopColor="#F2B220"/>
                <stop offset="1" stopColor="#A8632C"/>
            </radialGradient>
            <linearGradient id="paint6_linear_2615_1798" x1="3.5757" y1="17.9939" x2="3.5757" y2="8.99696" gradientUnits="userSpaceOnUse">
                <stop stopColor="#DB8E00"/>
                <stop offset="1" stopColor="#B87200"/>
            </linearGradient>
            <linearGradient id="paint7_linear_2615_1798" x1="7.30536" y1="16.2112" x2="7.30536" y2="8.99696" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFBD44"/>
                <stop offset="1" stopColor="#B87200"/>
            </linearGradient>
            <linearGradient id="paint8_linear_2615_1798" x1="362.356" y1="17.994" x2="362.356" y2="8.99696" gradientUnits="userSpaceOnUse">
                <stop stopColor="#DB8E00"/>
                <stop offset="1" stopColor="#B87200"/>
            </linearGradient>
            <linearGradient id="paint9_linear_2615_1798" x1="358.627" y1="16.2112" x2="358.627" y2="8.99696" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFBD44"/>
                <stop offset="1" stopColor="#B87200"/>
            </linearGradient>
            <linearGradient id="paint10_linear_2615_1798" x1="182.966" y1="17.994" x2="182.966" y2="16.2113" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C27800"/>
                <stop offset="1" stopColor="#EB9800"/>
            </linearGradient>
            <radialGradient id="paint11_radial_2615_1798" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.965 15.023) scale(176.611 115.955)">
                <stop stopColor="#FFE89C"/>
                <stop offset="1" stopColor="#E8B723"/>
            </radialGradient>
        </defs>
    </svg>

);
