import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';
import { useEffect, useState } from 'react';
import { StoryItem } from 'shared/interfaces/story';

export default function useGetCachedStories() {
    const [stories, setStories] = useState<StoryItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        getCachedStories()
                .then((stories) => {
                    setStories(stories.filter(story => story.active && story.showInMenu));
                })
                .finally(() => setIsLoading(false));
    }, []);

    const hasActiveStories = stories?.length > 0 ?? false;

    return { stories, isLoading, hasActiveStories };
}



type CachedStoriesResponseType = StoryItem[];

const getCachedStories = async () => {
    try {
        const response = await TaooServerClient.get<CachedStoriesResponseType>(endpoints.story.getHelpMenu);

        if (!response || !response.data) {
            throw new Error('API Error!');
        }

        return response.data;
    } catch (e: unknown) {
        console.error(e);
    }
};
