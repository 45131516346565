export const ProgressDayPreview1 = ({ className }: { className?: string }) => (
    <svg width="37" height="38" viewBox="0 0 37 38" fill="none" className={className}>
        <g clipPath="url(#clip0_2855_860)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.39683 2.84921C10.7183 2.2619 12.3333 1.6746 13.8016 1.38095C15.2698 0.940476 16.8849 0.646825 18.3532 0.5H18.5H18.6468C20.1151 0.646825 21.877 0.940476 23.1984 1.38095C24.6667 1.82143 26.2817 2.2619 27.6032 2.84921H27.75L27.8968 2.99603C29.0714 3.87698 30.3929 5.05159 31.4206 6.07937C32.4484 7.10714 33.623 8.42857 34.504 9.60317L34.6508 9.75V9.89683C35.2381 11.2183 35.8254 12.8333 36.119 14.3016C36.5595 15.7698 36.8532 17.3849 37 18.8532V19V19.1468C36.8532 20.6151 36.5595 22.377 36.119 23.6984C35.6786 25.1667 35.2381 26.7817 34.6508 28.1032V28.25L34.504 28.3968C33.623 29.5714 32.4484 30.8929 31.4206 31.9206C30.3929 32.9484 29.0714 34.123 27.8968 35.004L27.75 35.1508H27.6032C26.2817 35.7381 24.6667 36.3254 23.1984 36.619C21.7302 37.0595 20.1151 37.3532 18.6468 37.5H18.5H18.3532C16.8849 37.3532 15.123 37.0595 13.8016 36.619C12.3333 36.1786 10.7183 35.7381 9.39683 35.1508H9.25L9.10317 35.004C7.92857 34.123 6.60714 32.9484 5.57937 31.9206C4.55159 30.8929 3.37698 29.5714 2.49603 28.3968L2.34921 28.25V28.1032C1.7619 26.7817 1.1746 25.1667 0.880952 23.6984C0.440476 22.2302 0.146825 20.6151 0 19.1468V19V18.8532C0.146825 17.3849 0.440476 15.623 0.880952 14.3016C1.32143 12.8333 1.7619 11.2183 2.34921 9.89683V9.75L2.49603 9.60317C3.37698 8.42857 4.55159 7.10714 5.57937 6.07937C6.60714 5.05159 7.92857 3.87698 9.10317 2.99603L9.39683 2.84921Z" fill="#232C45"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.0959 2.40874C15.4173 1.96826 17.0324 1.67461 18.5006 1.52778V0.35318H18.3538C16.8856 0.500005 15.1237 0.793656 13.8022 1.23413C12.334 1.67461 10.7189 2.11508 9.39746 2.70239H9.25064L9.10381 2.99604C7.92921 3.87699 6.60778 5.05159 5.58 6.07937C4.55222 7.10715 3.37762 8.42858 2.49667 9.60318V9.75001V9.89683C1.90937 11.2183 1.32206 12.8333 1.02841 14.3016C0.587937 15.7698 0.294286 17.3849 0.147461 18.8532V19H1.32206C1.46889 17.5318 1.76254 16.0635 2.20302 14.5952C2.64349 13.2738 2.93714 11.6587 3.67127 10.3373C4.4054 9.1627 5.58 7.9881 6.60778 6.96032C7.63556 5.93255 8.81016 4.90477 9.98476 4.02381C11.1594 3.28969 12.6276 2.84921 14.0959 2.40874Z" fill="url(#paint0_linear_2855_860)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.6475 1.6746C20.1157 1.82143 21.584 2.11508 23.0522 2.55556C24.3737 2.99603 25.9887 3.43651 27.3102 4.02381C28.4848 4.75794 29.6594 5.93254 30.6871 6.96032C31.7149 7.9881 32.7427 9.1627 33.6236 10.3373C34.211 11.6587 34.6514 13.127 35.0919 14.5952C35.5324 15.9167 35.826 17.5317 35.9729 19H37.1475V18.8532C37.0006 17.3849 36.707 15.623 36.2665 14.3016C35.826 12.8333 35.3856 11.2183 34.7983 9.89683V9.75L34.6514 9.60317C33.7705 8.42857 32.5959 7.10714 31.5681 6.07937C30.5403 5.05159 29.2189 3.87698 28.0443 2.99603L27.8975 2.84921H27.7506C26.4292 2.2619 24.8141 1.6746 23.3459 1.38095C21.8776 0.940476 20.2625 0.646825 18.7943 0.5H18.6475V1.6746Z" fill="url(#paint1_linear_2855_860)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9048 2.40872C21.5833 1.96825 19.9683 1.6746 18.5 1.52777V2.70237C19.381 2.70237 20.8492 2.99602 22.6111 3.58333C24.373 4.0238 25.8413 4.6111 26.5754 4.90476C27.3095 5.34523 28.4841 6.37301 29.8056 7.69444C31.127 9.01587 32.1548 10.1905 32.5952 10.9246C33.0357 11.6587 33.4762 13.127 33.9167 14.8889C34.3571 16.6508 34.6508 18.119 34.7976 19H35.9722C35.8254 17.5317 35.5317 16.0635 35.0913 14.5952C34.6508 13.2738 34.3571 11.6587 33.623 10.3373C32.8889 9.16269 31.7143 7.98809 30.6865 6.96031C29.6587 5.93253 28.4841 4.90476 27.3095 4.0238C25.8413 3.28968 24.373 2.8492 22.9048 2.40872Z" fill="url(#paint2_linear_2855_860)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.5002 2.70237V1.52777C17.0319 1.6746 15.5637 1.96825 14.0954 2.40872C12.774 2.8492 11.1589 3.28968 9.8375 3.87698C8.6629 4.6111 7.4883 5.78571 6.46052 6.81349C5.43274 7.84126 4.40496 9.01587 3.52401 10.1905C2.93671 11.5119 2.49623 12.9802 2.05576 14.4484C1.61528 15.7698 1.32163 17.3849 1.1748 18.8532H2.34941C2.34941 17.9722 2.64306 16.504 3.23036 14.7421C3.52401 13.127 3.96449 11.6587 4.40496 10.9246C4.84544 10.1905 5.87322 9.01587 7.19465 7.69444C8.51607 6.37301 9.69068 5.34523 10.4248 4.90475C11.1589 4.46428 12.6272 4.0238 14.3891 3.58333C16.151 3.14285 17.6192 2.8492 18.5002 2.70237Z" fill="url(#paint3_linear_2855_860)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.90907 23.4048C1.46859 22.0833 1.17494 20.4683 1.02812 19H-0.146484V19.1468C0.000341024 20.6151 0.293992 22.377 0.734468 23.6984C1.17494 25.1667 1.61542 26.7817 2.20272 28.1032V28.25L2.49637 28.3968C3.37733 29.5714 4.55193 30.8929 5.57971 31.9206C6.60748 32.9484 7.92891 34.123 9.10352 35.004L9.25034 35.1508H9.39717C10.7186 35.7381 12.3337 36.3254 13.8019 36.619C15.2702 37.0595 16.8853 37.3532 18.3535 37.5V36.3254C16.8853 36.1786 15.417 35.8849 14.0956 35.4444C12.7742 35.004 11.1591 34.5635 9.83764 33.9762C8.66304 33.2421 7.48844 32.0675 6.46066 31.0397C5.43288 30.0119 4.4051 28.8373 3.52415 27.6627C2.79002 26.3413 2.34955 24.873 1.90907 23.4048Z" fill="url(#paint4_linear_2855_860)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.1748 19.1468C1.32163 20.6151 1.61528 22.0833 2.05576 23.5516C2.49623 24.873 2.93671 26.4881 3.52401 27.8095C4.25814 28.9841 5.43274 30.1587 6.46052 31.1865C7.4883 32.2143 8.6629 33.2421 9.8375 34.123C11.1589 34.7103 12.6272 35.1508 14.0954 35.5913C15.4169 35.8849 17.0319 36.3254 18.3534 36.4722V35.2976C17.4724 35.1508 16.0042 35.004 14.3891 34.5635C12.6272 34.123 11.1589 33.5357 10.4248 33.2421C9.69068 32.8016 8.51607 31.7738 7.19465 30.4524C5.87322 29.1309 4.84544 27.8095 4.40496 27.2222C3.96449 26.4881 3.52401 25.0198 3.08353 23.2579C2.64306 21.496 2.34941 20.0278 2.20258 19.1468H1.1748Z" fill="url(#paint5_linear_2855_860)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.6115 34.4167C20.8495 34.8571 19.3813 35.1508 18.5003 35.2976H18.3535V36.4722C19.8218 36.3254 21.29 36.0317 22.7583 35.5913C24.0797 35.1508 25.6948 34.7103 27.0162 34.123C28.1908 33.3889 29.3654 32.2143 30.3932 31.1865C31.421 30.1587 32.4488 28.9841 33.3297 27.8095C33.917 26.4881 34.3575 25.0198 34.798 23.5516C35.2384 22.2302 35.5321 20.6151 35.6789 19.1468H34.5043C34.5043 20.0278 34.2107 21.496 33.6234 23.2579C33.1829 25.0198 32.5956 26.4881 32.3019 27.2222C31.8615 27.9563 30.8337 29.1309 29.5122 30.4524C28.1908 31.7738 27.0162 32.8016 26.2821 33.2421C25.8416 33.5357 24.3734 33.9762 22.6115 34.4167Z" fill="url(#paint6_linear_2855_860)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9048 35.5913C21.5833 36.0317 19.9683 36.3254 18.5 36.4722V37.6468H18.6468H18.7937C20.2619 37.5 22.0238 37.2063 23.3452 36.7659C24.8135 36.3254 26.4286 35.8849 27.75 35.2976H27.8968L28.0437 35.1508C29.2183 34.2698 30.5397 33.0952 31.5675 32.0675C32.5952 31.0397 33.7698 29.7182 34.6508 28.5436L34.7976 28.3968V28.25C35.3849 26.9286 35.9722 25.3135 36.2659 23.8452C36.7063 22.377 37 20.7619 37.1468 19.2936V19.1468H35.9722C35.8254 20.6151 35.5317 22.0833 35.0913 23.5516C34.6508 24.873 34.2103 26.4881 33.623 27.8095C32.8889 28.9841 31.7143 30.1587 30.6865 31.1865C29.6587 32.2143 28.4841 33.2421 27.3095 34.123C25.8413 34.7103 24.373 35.1508 22.9048 35.5913Z" fill="url(#paint7_linear_2855_860)"/>
            <path opacity="0.75" fillRule="evenodd" clipRule="evenodd" d="M4.99219 8.42857C6.31362 6.8135 7.9287 5.19842 9.54377 3.73016C12.6271 2.55556 15.5636 1.82143 18.5001 1.23413C21.143 1.82143 23.639 2.70238 26.135 3.58334C23.639 2.99604 21.143 2.26191 18.5001 1.96826C15.5636 2.26191 12.6271 2.99604 9.98425 4.31746C8.07552 5.49207 6.46044 6.96032 4.99219 8.42857Z" fill="#FFFECA"/>
            <path opacity="0.75" fillRule="evenodd" clipRule="evenodd" d="M32.0081 29.7183C30.6867 31.3333 29.0716 32.9484 27.4565 34.4167C24.3732 35.5913 21.4367 36.3254 18.5002 36.9127C15.8573 36.3254 13.3613 35.4445 10.8652 34.5635C13.3613 35.1508 15.8573 35.8849 18.5002 36.1786C21.4367 35.8849 24.3732 35.1508 27.016 33.8294C28.9248 32.5079 30.5398 31.0397 32.0081 29.7183Z" fill="#FFFECA"/>
            <mask id="path-12-outside-1_2855_860" maskUnits="userSpaceOnUse" x="13.8584" y="9.82053" width="9" height="18" fill="black">
                <rect fill="white" x="13.8584" y="9.82053" width="9" height="18"/>
                <path d="M15.065 14.6392V11.2472H20.9743V26.8205H17.177V14.6392H15.065Z"/>
            </mask>
            <path d="M15.065 14.6392V11.2472H20.9743V26.8205H17.177V14.6392H15.065Z" fill="white"/>
            <path d="M15.065 14.6392H14.065V15.6392H15.065V14.6392ZM15.065 11.2472V10.2472H14.065V11.2472H15.065ZM20.9743 11.2472H21.9743V10.2472H20.9743V11.2472ZM20.9743 26.8205V27.8205H21.9743V26.8205H20.9743ZM17.177 26.8205H16.177V27.8205H17.177V26.8205ZM17.177 14.6392H18.177V13.6392H17.177V14.6392ZM16.065 14.6392V11.2472H14.065V14.6392H16.065ZM15.065 12.2472H20.9743V10.2472H15.065V12.2472ZM19.9743 11.2472V26.8205H21.9743V11.2472H19.9743ZM20.9743 25.8205H17.177V27.8205H20.9743V25.8205ZM18.177 26.8205V14.6392H16.177V26.8205H18.177ZM17.177 13.6392H15.065V15.6392H17.177V13.6392Z" fill="#1A1F2C" mask="url(#path-12-outside-1_2855_860)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_2855_860" x1="9.25533" y1="18.9564" x2="9.25533" y2="0.50074" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D19300"/>
                <stop offset="1" stopColor="#FFE53B"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2855_860" x1="27.7683" y1="19.0721" x2="27.7683" y2="0.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D19300"/>
                <stop offset="1" stopColor="#FFDD00"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2855_860" x1="27.2066" y1="19.0686" x2="27.2066" y2="1.61866" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFCC00"/>
                <stop offset="1" stopColor="#BD6E00"/>
            </linearGradient>
            <linearGradient id="paint3_linear_2855_860" x1="9.81225" y1="18.9601" x2="9.81225" y2="1.61954" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDD00"/>
                <stop offset="1" stopColor="#CF7900"/>
            </linearGradient>
            <linearGradient id="paint4_linear_2855_860" x1="9.19513" y1="37.5253" x2="9.19513" y2="18.9527" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BD6E00"/>
                <stop offset="1" stopColor="#FFCC00"/>
            </linearGradient>
            <linearGradient id="paint5_linear_2855_860" x1="9.75601" y1="36.4067" x2="9.75601" y2="18.9564" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFE53B"/>
                <stop offset="1" stopColor="#D19300"/>
            </linearGradient>
            <linearGradient id="paint6_linear_2855_860" x1="27.151" y1="36.4151" x2="27.151" y2="19.0652" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDD00"/>
                <stop offset="1" stopColor="#D19300"/>
            </linearGradient>
            <linearGradient id="paint7_linear_2855_860" x1="27.708" y1="37.5336" x2="27.708" y2="19.0686" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7900"/>
                <stop offset="1" stopColor="#FFDD00"/>
            </linearGradient>
            <clipPath id="clip0_2855_860">
                <rect width="37" height="37" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>

);

ProgressDayPreview1.defaultProps = {
    className: undefined,
};
