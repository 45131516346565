import isEmpty from 'lodash/isEmpty';

const replacePathParams = (path, params, prefix = ':', queryParams = {}) => {
    let newPath = path;

    Object.entries(params).forEach(([key, value]) => {
        newPath = newPath.replace(prefix + key, value);
    });

    if (!isEmpty(queryParams)) {
        let isFirst = true;

        Object.entries(queryParams).map(([key, value]) => {
            newPath += `${ isFirst ? '?' : '&' }${ key }=${ value }`;
            isFirst = false;
        });
    }

    return newPath;
};

export default replacePathParams;
