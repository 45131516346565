import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

interface TooltipAPI {
    updateValue: ({ key, viewed }: UseIntroTooltipAPIOptions) => Promise<any>;
}
interface UseIntroTooltipAPIOptions {
    key: string;
    viewed: boolean;
}

export default function useIntroTooltipAPI(): TooltipAPI {
    const updateValue = ({ key, viewed }: UseIntroTooltipAPIOptions) => postTooltipView(key, viewed);

    return { updateValue };
}

const postTooltipView = async (key: string, viewed: boolean) => {
    try {
        const response = await TaooServerClient.post(endpoints.introTooltip.postView, { key, viewed });
        const data = response?.data;

        if (!data) {
            throw new Error('No response!');
        }

        return data;
    } catch (error: unknown) {
        console.error(error);
    }
};
