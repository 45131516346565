export const ProgressDayPreview7 = ({ className }: {className?: string}) => (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" className={className}>
        <g clipPath="url(#clip0_2855_844)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.39683 2.34921C10.7183 1.7619 12.3333 1.1746 13.8016 0.880952C15.2698 0.440476 16.8849 0.146825 18.3532 0H18.5H18.6468C20.1151 0.146825 21.877 0.440476 23.1984 0.880952C24.6667 1.32143 26.2817 1.7619 27.6032 2.34921H27.75L27.8968 2.49603C29.0714 3.37698 30.3929 4.55159 31.4206 5.57937C32.4484 6.60714 33.623 7.92857 34.504 9.10317L34.6508 9.25V9.39683C35.2381 10.7183 35.8254 12.3333 36.119 13.8016C36.5595 15.2698 36.8532 16.8849 37 18.3532V18.5V18.6468C36.8532 20.1151 36.5595 21.877 36.119 23.1984C35.6786 24.6667 35.2381 26.2817 34.6508 27.6032V27.75L34.504 27.8968C33.623 29.0714 32.4484 30.3929 31.4206 31.4206C30.3929 32.4484 29.0714 33.623 27.8968 34.504L27.75 34.6508H27.6032C26.2817 35.2381 24.6667 35.8254 23.1984 36.119C21.7302 36.5595 20.1151 36.8532 18.6468 37H18.5H18.3532C16.8849 36.8532 15.123 36.5595 13.8016 36.119C12.3333 35.6786 10.7183 35.2381 9.39683 34.6508H9.25L9.10317 34.504C7.92857 33.623 6.60714 32.4484 5.57937 31.4206C4.55159 30.3929 3.37698 29.0714 2.49603 27.8968L2.34921 27.75V27.6032C1.7619 26.2817 1.1746 24.6667 0.880952 23.1984C0.440476 21.7302 0.146825 20.1151 0 18.6468V18.5V18.3532C0.146825 16.8849 0.440476 15.123 0.880952 13.8016C1.32143 12.3333 1.7619 10.7183 2.34921 9.39683V9.25L2.49603 9.10317C3.37698 7.92857 4.55159 6.60714 5.57937 5.57937C6.60714 4.55159 7.92857 3.37698 9.10317 2.49603L9.39683 2.34921Z" fill="#232C45"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.0959 1.90874C15.4173 1.46826 17.0324 1.17461 18.5006 1.02778V-0.14682H18.3538C16.8856 5.33084e-06 15.1237 0.293656 13.8022 0.734132C12.334 1.17461 10.7189 1.61508 9.39746 2.20239H9.25064L9.10381 2.49604C7.92921 3.37699 6.60778 4.55159 5.58 5.57937C4.55222 6.60715 3.37762 7.92858 2.49667 9.10318V9.25001V9.39683C1.90937 10.7183 1.32206 12.3333 1.02841 13.8016C0.587937 15.2698 0.294286 16.8849 0.147461 18.3532V18.5H1.32206C1.46889 17.0318 1.76254 15.5635 2.20302 14.0952C2.64349 12.7738 2.93714 11.1587 3.67127 9.83731C4.4054 8.6627 5.58 7.4881 6.60778 6.46032C7.63556 5.43255 8.81016 4.40477 9.98476 3.52381C11.1594 2.78969 12.6276 2.34921 14.0959 1.90874Z" fill="url(#paint0_linear_2855_844)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.6475 1.1746C20.1157 1.32143 21.584 1.61508 23.0522 2.05556C24.3737 2.49603 25.9887 2.93651 27.3102 3.52381C28.4848 4.25794 29.6594 5.43254 30.6871 6.46032C31.7149 7.4881 32.7427 8.6627 33.6236 9.8373C34.211 11.1587 34.6514 12.627 35.0919 14.0952C35.5324 15.4167 35.826 17.0317 35.9729 18.5H37.1475V18.3532C37.0006 16.8849 36.707 15.123 36.2665 13.8016C35.826 12.3333 35.3856 10.7183 34.7983 9.39683V9.25L34.6514 9.10317C33.7705 7.92857 32.5959 6.60714 31.5681 5.57937C30.5403 4.55159 29.2189 3.37698 28.0443 2.49603L27.8975 2.34921H27.7506C26.4292 1.7619 24.8141 1.1746 23.3459 0.880952C21.8776 0.440476 20.2625 0.146825 18.7943 0H18.6475V1.1746Z" fill="url(#paint1_linear_2855_844)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9048 1.90872C21.5833 1.46825 19.9683 1.1746 18.5 1.02777V2.20237C19.381 2.20237 20.8492 2.49602 22.6111 3.08333C24.373 3.5238 25.8413 4.1111 26.5754 4.40476C27.3095 4.84523 28.4841 5.87301 29.8056 7.19444C31.127 8.51587 32.1548 9.69047 32.5952 10.4246C33.0357 11.1587 33.4762 12.627 33.9167 14.3889C34.3571 16.1508 34.6508 17.619 34.7976 18.5H35.9722C35.8254 17.0317 35.5317 15.5635 35.0913 14.0952C34.6508 12.7738 34.3571 11.1587 33.623 9.83729C32.8889 8.66269 31.7143 7.48809 30.6865 6.46031C29.6587 5.43253 28.4841 4.40476 27.3095 3.5238C25.8413 2.78968 24.373 2.3492 22.9048 1.90872Z" fill="url(#paint2_linear_2855_844)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.5002 2.20237V1.02777C17.0319 1.1746 15.5637 1.46825 14.0954 1.90872C12.774 2.3492 11.1589 2.78968 9.8375 3.37698C8.6629 4.1111 7.4883 5.28571 6.46052 6.31349C5.43274 7.34126 4.40496 8.51587 3.52401 9.69047C2.93671 11.0119 2.49623 12.4802 2.05576 13.9484C1.61528 15.2698 1.32163 16.8849 1.1748 18.3532H2.34941C2.34941 17.4722 2.64306 16.004 3.23036 14.2421C3.52401 12.627 3.96449 11.1587 4.40496 10.4246C4.84544 9.69047 5.87322 8.51587 7.19465 7.19444C8.51607 5.87301 9.69068 4.84523 10.4248 4.40475C11.1589 3.96428 12.6272 3.5238 14.3891 3.08333C16.151 2.64285 17.6192 2.3492 18.5002 2.20237Z" fill="url(#paint3_linear_2855_844)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.90907 22.9048C1.46859 21.5833 1.17494 19.9683 1.02812 18.5H-0.146484V18.6468C0.000341024 20.1151 0.293992 21.877 0.734468 23.1984C1.17494 24.6667 1.61542 26.2817 2.20272 27.6032V27.75L2.49637 27.8968C3.37733 29.0714 4.55193 30.3929 5.57971 31.4206C6.60748 32.4484 7.92891 33.623 9.10352 34.504L9.25034 34.6508H9.39717C10.7186 35.2381 12.3337 35.8254 13.8019 36.119C15.2702 36.5595 16.8853 36.8532 18.3535 37V35.8254C16.8853 35.6786 15.417 35.3849 14.0956 34.9444C12.7742 34.504 11.1591 34.0635 9.83764 33.4762C8.66304 32.7421 7.48844 31.5675 6.46066 30.5397C5.43288 29.5119 4.4051 28.3373 3.52415 27.1627C2.79002 25.8413 2.34955 24.373 1.90907 22.9048Z" fill="url(#paint4_linear_2855_844)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.1748 18.6468C1.32163 20.1151 1.61528 21.5833 2.05576 23.0516C2.49623 24.373 2.93671 25.9881 3.52401 27.3095C4.25814 28.4841 5.43274 29.6587 6.46052 30.6865C7.4883 31.7143 8.6629 32.7421 9.8375 33.623C11.1589 34.2103 12.6272 34.6508 14.0954 35.0913C15.4169 35.3849 17.0319 35.8254 18.3534 35.9722V34.7976C17.4724 34.6508 16.0042 34.504 14.3891 34.0635C12.6272 33.623 11.1589 33.0357 10.4248 32.7421C9.69068 32.3016 8.51607 31.2738 7.19465 29.9524C5.87322 28.6309 4.84544 27.3095 4.40496 26.7222C3.96449 25.9881 3.52401 24.5198 3.08353 22.7579C2.64306 20.996 2.34941 19.5278 2.20258 18.6468H1.1748Z" fill="url(#paint5_linear_2855_844)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.6115 33.9167C20.8495 34.3571 19.3813 34.6508 18.5003 34.7976H18.3535V35.9722C19.8218 35.8254 21.29 35.5317 22.7583 35.0913C24.0797 34.6508 25.6948 34.2103 27.0162 33.623C28.1908 32.8889 29.3654 31.7143 30.3932 30.6865C31.421 29.6587 32.4488 28.4841 33.3297 27.3095C33.917 25.9881 34.3575 24.5198 34.798 23.0516C35.2384 21.7302 35.5321 20.1151 35.6789 18.6468H34.5043C34.5043 19.5278 34.2107 20.996 33.6234 22.7579C33.1829 24.5198 32.5956 25.9881 32.3019 26.7222C31.8615 27.4563 30.8337 28.6309 29.5122 29.9524C28.1908 31.2738 27.0162 32.3016 26.2821 32.7421C25.8416 33.0357 24.3734 33.4762 22.6115 33.9167Z" fill="url(#paint6_linear_2855_844)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9048 35.0913C21.5833 35.5317 19.9683 35.8254 18.5 35.9722V37.1468H18.6468H18.7937C20.2619 37 22.0238 36.7063 23.3452 36.2659C24.8135 35.8254 26.4286 35.3849 27.75 34.7976H27.8968L28.0437 34.6508C29.2183 33.7698 30.5397 32.5952 31.5675 31.5675C32.5952 30.5397 33.7698 29.2182 34.6508 28.0436L34.7976 27.8968V27.75C35.3849 26.4286 35.9722 24.8135 36.2659 23.3452C36.7063 21.877 37 20.2619 37.1468 18.7936V18.6468H35.9722C35.8254 20.1151 35.5317 21.5833 35.0913 23.0516C34.6508 24.373 34.2103 25.9881 33.623 27.3095C32.8889 28.4841 31.7143 29.6587 30.6865 30.6865C29.6587 31.7143 28.4841 32.7421 27.3095 33.623C25.8413 34.2103 24.373 34.6508 22.9048 35.0913Z" fill="url(#paint7_linear_2855_844)"/>
            <path opacity="0.75" fillRule="evenodd" clipRule="evenodd" d="M4.99219 7.92857C6.31362 6.3135 7.9287 4.69842 9.54377 3.23016C12.6271 2.05556 15.5636 1.32143 18.5001 0.734131C21.143 1.32143 23.639 2.20238 26.135 3.08334C23.639 2.49604 21.143 1.76191 18.5001 1.46826C15.5636 1.76191 12.6271 2.49604 9.98425 3.81746C8.07552 4.99207 6.46044 6.46032 4.99219 7.92857Z" fill="#FFFECA"/>
            <path opacity="0.75" fillRule="evenodd" clipRule="evenodd" d="M32.0081 29.2183C30.6867 30.8333 29.0716 32.4484 27.4565 33.9167C24.3732 35.0913 21.4367 35.8254 18.5002 36.4127C15.8573 35.8254 13.3613 34.9445 10.8652 34.0635C13.3613 34.6508 15.8573 35.3849 18.5002 35.6786C21.4367 35.3849 24.3732 34.6508 27.016 33.3294C28.9248 32.0079 30.5398 30.5397 32.0081 29.2183Z" fill="#FFFECA"/>
            <mask id="path-12-outside-1_2855_844" maskUnits="userSpaceOnUse" x="11.8594" y="9.32053" width="13" height="18" fill="black">
                <rect fill="white" x="11.8594" y="9.32053" width="13" height="18"/>
                <path d="M23.6934 13.4779L18.3387 26.3205H14.6054L20.0241 13.8832H13.3894V10.7685H23.6934V13.4779Z"/>
            </mask>
            <path d="M23.6934 13.4779L18.3387 26.3205H14.6054L20.0241 13.8832H13.3894V10.7685H23.6934V13.4779Z" fill="white"/>
            <path d="M23.6934 13.4779L24.6164 13.8627L24.6934 13.678V13.4779H23.6934ZM18.3387 26.3205V27.3205H19.0052L19.2617 26.7054L18.3387 26.3205ZM14.6054 26.3205L13.6886 25.9211L13.0789 27.3205H14.6054V26.3205ZM20.0241 13.8832L20.9408 14.2826L21.5505 12.8832H20.0241V13.8832ZM13.3894 13.8832H12.3894V14.8832H13.3894V13.8832ZM13.3894 10.7685V9.76852H12.3894V10.7685H13.3894ZM23.6934 10.7685H24.6934V9.76852H23.6934V10.7685ZM22.7704 13.093L17.4157 25.9357L19.2617 26.7054L24.6164 13.8627L22.7704 13.093ZM18.3387 25.3205H14.6054V27.3205H18.3387V25.3205ZM15.5222 26.7199L20.9408 14.2826L19.1073 13.4838L13.6886 25.9211L15.5222 26.7199ZM20.0241 12.8832H13.3894V14.8832H20.0241V12.8832ZM14.3894 13.8832V10.7685H12.3894V13.8832H14.3894ZM13.3894 11.7685H23.6934V9.76852H13.3894V11.7685ZM22.6934 10.7685V13.4779H24.6934V10.7685H22.6934Z" fill="#1A1F2C" mask="url(#path-12-outside-1_2855_844)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_2855_844" x1="9.25533" y1="18.4564" x2="9.25533" y2="0.000739693" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D19300"/>
                <stop offset="1" stopColor="#FFE53B"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2855_844" x1="27.7683" y1="18.5721" x2="27.7683" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D19300"/>
                <stop offset="1" stopColor="#FFDD00"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2855_844" x1="27.2066" y1="18.5686" x2="27.2066" y2="1.11866" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFCC00"/>
                <stop offset="1" stopColor="#BD6E00"/>
            </linearGradient>
            <linearGradient id="paint3_linear_2855_844" x1="9.81225" y1="18.4601" x2="9.81225" y2="1.11954" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDD00"/>
                <stop offset="1" stopColor="#CF7900"/>
            </linearGradient>
            <linearGradient id="paint4_linear_2855_844" x1="9.19513" y1="37.0253" x2="9.19513" y2="18.4527" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BD6E00"/>
                <stop offset="1" stopColor="#FFCC00"/>
            </linearGradient>
            <linearGradient id="paint5_linear_2855_844" x1="9.75601" y1="35.9067" x2="9.75601" y2="18.4564" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFE53B"/>
                <stop offset="1" stopColor="#D19300"/>
            </linearGradient>
            <linearGradient id="paint6_linear_2855_844" x1="27.151" y1="35.9151" x2="27.151" y2="18.5652" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDD00"/>
                <stop offset="1" stopColor="#D19300"/>
            </linearGradient>
            <linearGradient id="paint7_linear_2855_844" x1="27.708" y1="37.0336" x2="27.708" y2="18.5686" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7900"/>
                <stop offset="1" stopColor="#FFDD00"/>
            </linearGradient>
            <clipPath id="clip0_2855_844">
                <rect width="37" height="37" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

ProgressDayPreview7.defaultProps = {
    className: undefined,
};
