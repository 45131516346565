import { useSelector } from 'react-redux';

interface CurrentUser {
    id: string;
    firstName: string;
    lastName: string;
    hasPin: boolean;
    login: string;
    onboarded: boolean;
    onboardingStep: number;
    pin: string;
    token: string;
    tooltips: {
        HELP_TOOLTIP_SHOP: boolean;
        HELP_TOOLTIP_OFFER: boolean;
        HELP_TOOLTIP_PROFILE: boolean;
    };
    totalPoints: number;
}

export default function useGetCurrentUser() {
    const currentUser = useSelector((state) => state.user) as CurrentUser;

    return currentUser;
}
