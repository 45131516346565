export function Alert({ onClick, style, className, fill }) {
    return (
        <svg onClick={onClick} style={style} className={className} viewBox="0 0 24 24" width="24" height="24">
            <path fill={fill} d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 15a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm1-4a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0z"/>
        </svg>
    )
}

Alert.defaultProps = {
    onClick: undefined, 
    style: undefined, 
    className: undefined, 
    fill: '#000'
}
