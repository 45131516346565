export const ProgressTubeFill = ({ className }: { className?: string }) => (
    <svg className={className} width="66" height="21" viewBox="0 0 66 21" fill="none">
        <g filter="url(#filter0_d_2664_821)">
            <path d="M4.85254 4.61035H61.9733V16.6104H4.85254V4.61035Z" fill="#FFD100"/>
            <path d="M5.35254 5.11035H61.4733V16.1104H5.35254V5.11035Z" stroke="#FFE56E"/>
        </g>
        <g opacity="0.23">
            <mask id="mask0_2664_821" maskUnits="userSpaceOnUse" x="4" y="5" width="58" height="11">
                <path d="M5.19555 15.7929C4.53593 15.7929 4 14.2713 4 12.3987V9.00457C4 7.13194 4.53593 5.61042 5.19555 5.61042L61.0977 5.61035C61.0977 5.61035 61.0977 7.13194 61.0977 9.00457V12.3987C61.0977 14.2713 60.5617 15.7929 59.9021 15.7929H5.19555Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_2664_821)">
                <path d="M4.65773 27.4968H1.23828L13.8799 -6.50323H17.2993L4.65773 27.4968Z" fill="url(#paint0_linear_2664_821)"/>
                <path d="M11.8589 27.4968H8.43945L21.081 -6.50323H24.5005L11.8589 27.4968Z" fill="url(#paint1_linear_2664_821)"/>
                <path d="M19.1128 27.4968H15.6934L28.335 -6.50323H31.7026L19.1128 27.4968Z" fill="url(#paint2_linear_2664_821)"/>
                <path d="M26.314 27.4968H22.8945L35.5361 -6.50323H38.9556L26.314 27.4968Z" fill="url(#paint3_linear_2664_821)"/>
                <path d="M33.5161 27.4968H30.0967L42.7383 -6.50323H46.1577L33.5161 27.4968Z" fill="url(#paint4_linear_2664_821)"/>
                <path d="M40.7691 27.4968H37.3496L49.9912 -6.50323H53.3588L40.7691 27.4968Z" fill="url(#paint5_linear_2664_821)"/>
                <path d="M47.9702 27.4968H44.5508L57.1924 -6.50323H60.6118L47.9702 27.4968Z" fill="url(#paint6_linear_2664_821)"/>
                <path d="M55.1724 27.4968H51.7529L64.3945 -6.50323H67.814L55.1724 27.4968Z" fill="url(#paint7_linear_2664_821)"/>
            </g>
        </g>
        <defs>
            <filter id="filter0_d_2664_821" x="0.852539" y="0.610352" width="65.1211" height="20" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.941176 0 0 0 0 0.772549 0 0 0 0 0.0784314 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2664_821"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2664_821" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_2664_821" x1="9.27885" y1="31.1136" x2="9.27885" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2664_821" x1="16.48" y1="31.1136" x2="16.48" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2664_821" x1="23.708" y1="31.1136" x2="23.708" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
            <linearGradient id="paint3_linear_2664_821" x1="30.9351" y1="31.1136" x2="30.9351" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
            <linearGradient id="paint4_linear_2664_821" x1="38.1372" y1="31.1136" x2="38.1372" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
            <linearGradient id="paint5_linear_2664_821" x1="45.3642" y1="31.1136" x2="45.3642" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
            <linearGradient id="paint6_linear_2664_821" x1="52.5914" y1="31.1136" x2="52.5914" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
            <linearGradient id="paint7_linear_2664_821" x1="59.7935" y1="31.1136" x2="59.7935" y2="-19.8596" gradientUnits="userSpaceOnUse">
                <stop stopColor="#876D01"/>
                <stop offset="1" stopColor="#FFD102"/>
            </linearGradient>
        </defs>
    </svg>

);

ProgressTubeFill.defaultProps = {
    className: undefined,
};
