import React from 'react';

export function Polygon({ className, style, fill }) {
    return (
        <svg className={className} viewBox="0 0 20 28" fill="none" style={style}>
            <path
                d="M18.194 11.6856C19.6488 12.8856 19.6488 15.1144 18.194 16.3144L4.90886 27.2718C2.95214 28.8856 -1.50794e-06 27.4938 -1.53819e-06 24.9574L-1.79952e-06 3.04261C-1.82976e-06 0.506207 2.95213 -0.88563 4.90885 0.728246L18.194 11.6856Z"
                fill={fill || '#0E1118'}
            />
        </svg>
    );
}
